import { SectionHeading } from "src/containers/Landing/components/section-heading/SectionHeading";
import { BasicCarousel } from "src/components/basic-carousel/BasicCarousel";
import { CarouselItem } from "src/components/carousel/Carousel";
import { SkeletonCard } from "src/components/skeleton-card/SkeletonCard";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import useGamesList from "src/games/hooks/useGamesList";
import { GameCardCarouselItem } from "src/components/game-card-carousel-item/GameCardCarouselItem";

export const BigWinsSection = () => {
    // TODO: Use correct endpoint when it'll be ready
    const { games, isLoading } = useGamesList(CasinoGameCategory.Slots, 10);

    return (
        <section>
            <SectionHeading
                title="Recent Big Wins"
            />

            <BasicCarousel arrowsClassName="mt-4">
                {isLoading ? Array.from({ length: 10 }).map((_, i) => (
                    <CarouselItem key={i} className="min-w-[271px]">
                        <SkeletonCard />
                    </CarouselItem>
                )) : games.map(({
                    title,
                    thumbnailSrc,
                    url,
                    provider,
                    isDisabled,
                    gameId,
                }) => {
                    return (
                        <GameCardCarouselItem
                            key={gameId}
                            url={url}
                            provider={provider}
                            thumbnailSrc={thumbnailSrc}
                            title={title}
                            isDisabled={isDisabled}
                            variant="withText"
                            winAmount="310.87K"
                            userAvatar="https://peergame.com/assets/images/avatar/avatar-71.png"
                            userName="Lucknomore"
                        />
                    );
                })}
            </BasicCarousel>
        </section>
    )
}