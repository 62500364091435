import { Link } from "react-router-dom";
import { StyledItemContainer, StyledP } from "src/affiliate/components/side-nav/assets/styles/side-nav-menu-item";

interface ISideNavMenuItemProps {
    name: string;
    location: string;
    path: string;
    image: string;
}

const SideNavMenuItem = ({
    name,
    location,
    path,
    image,
}: ISideNavMenuItemProps) => (
    <>
        <StyledItemContainer visited={path.endsWith(location)} as={Link} to={location}>
            <img src={image} alt={name} />
            <StyledP>{name}</StyledP>
        </StyledItemContainer>
    </>
);

export default SideNavMenuItem;
