export const Path = {
    Home: "/",
    Games: "/games",
    InHouse: "/games/inhouse",
    Slots: "/games/slots",
    Crash: "/games/crash",
    Table: "/games/table",
    Casual: "/games/casual",
    Races: "/races",
    Blackjack: "/games/blackjack",
    Dice: "/games/dice",
    Wheel: "/games/wheel",
    Roulette: "/games/roulette",
    CoinFlip: "/games/coin-flip",
    Baccarat: "/games/baccarat",
    Ladder: "/games/ladder",
    TurtleRace: "/games/turtlerace",
    CoinBattles: "/games/coinbattles",
    Partners: "/partners",
    PartnersDashboard: "/partners/dashboard",
    ProvablyFair: "/provably-fair",
    Bsv: "/bitcoinsv",
    Payments: "/payments",
    ResponsibleGaming: "/responsible-gaming",
    AboutUs: "/about-us",
    FairnessCalculation: "/fairness-calculation",
    PrivacyPolicy: "/privacy-policy",
    TermsOfService: "/terms-of-service",
    Login: "/login",
    Complaints: "/complaints",
    Cookies: "/cookies",
    KycPolicy: "/kyc-policy",
    Dashboard: "/dashboard",
    Account: "/account",
} as const;

export type Path = (typeof Path)[keyof typeof Path];
