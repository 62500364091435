import { InHouseGame, GameCode } from "src/core/casino/casino.model";

export const gamesSeoList = [
    {
        game: InHouseGame.Blackjack,
        title: "Blackjack | Blockchain Casino Games | Peergame",
        description: "Enjoy classic Blackjack on Peergame, the blockchain casino offering secure gaming with direct payouts to your wallet. Play now and experience secure, transparent gameplay.",
    },
    {
        game: InHouseGame.Dice,
        title: "Dice | Blockchain Casino Games | Peergame",
        description: "Roll the Dice on Peergame and enjoy fast, secure blockchain gaming with direct wallet payouts. Play today and see where luck takes you!",
    },
    {
        game: InHouseGame.Wheel,
        title: "Wheel | Blockchain Casino Games | Peergame",
        description: "Test your luck with Wheel at Peergame. Enjoy a transparent blockchain gaming experience with instant payouts directly to your wallet.",
    },
    {
        game: InHouseGame.Roulette,
        title: "Roulette | Blockchain Casino Games | Peergame",
        description: "Spin the wheel in Roulette on Peergame, your go-to blockchain casino. Experience transparent, secure gaming with direct payouts to your wallet.",
    },
    {
        game: InHouseGame.Coinflip,
        title: "Coin Flip | Blockchain Casino Games | Peergame",
        description: "Try your luck with Coin Flip on Peergame, the blockchain casino offering secure, transparent gaming with instant payouts directly to your wallet.",
    },
    {
        game: InHouseGame.Baccarat,
        title: "Baccarat | Blockchain Casino Games | Peergame",
        description: "Play Baccarat at Peergame, the blockchain casino that offers secure and transparent gameplay with direct wallet payouts. Enjoy fast, private gaming now.",
    },
    {
        game: InHouseGame.Ladder,
        title: "Ladder | Blockchain Casino Games | Peergame",
        description: "Climb to new heights with Ladder at Peergame. Blockchain-based gaming with instant wallet payouts, no registration needed.",
    },
    {
        game: InHouseGame.Turtle,
        title: "Turtle Race | Blockchain Casino Games | Peergame",
        description: "Join the Turtle Race on Peergame and experience fun, secure blockchain gaming with direct payouts to your wallet. Play now and enjoy a seamless gaming experience.",
    },
    {
        game: InHouseGame.CfBattle,
        title: "Coin Battles | Blockchain Casino Games | Peergame",
        description: "Enter the Coin Battles at Peergame, where blockchain gaming meets strategy. Play securely with direct payouts to your wallet.",
    },
];

export const defaultSeoGame = {
    title: "Slots | Blockchain Casino Games | Peergame",
    description: "Enjoy fast, secure blockchain gaming at Peergame with direct wallet payouts. Simply connect your wallet and dive into the action—no registration needed!",
}

export const defaultSeoTitle = "Casino Games | Instant Wallet Payouts | Peergame"
export const defaultSeoMetaDescription = "Explore a variety of blockchain casino games at Peergame. Enjoy instant wallet payouts and a seamless gaming experience powered by blockchain technology."
export const slotsSeoTitle = "Slots | Win with Direct Wallet Payouts | Peergame"
export const slotsSeoMetaDescription = "Play the best blockchain slots at Peergame and enjoy direct payouts to your wallet. Experience secure and transparent gaming with no registration required."

export const getSeoContentForGame = (gameCode: GameCode): {
    title: string,
    description: string
} => gamesSeoList.find(({ game }) => game === gameCode) || defaultSeoGame
