import { cn } from "src/helpers/cn";

function Skeleton({
    className,
    ...props
}: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className={cn("relative rounded-md bg-brand-primary overflow-hidden", className)}
            {...props}
        >
            <div className="absolute h-full w-[200px] top-0 left-0 bg-gradient-to-r from-[#22096C] via-[#311C6D] to-[#22096C] animate-skeleton-loading" />
        </div>
    )
}

export { Skeleton }
