import classNames from "classnames/bind";
import styles from "./assets/styles.scss";
import { useMobileOnly } from "src/helpers/responsive";
import { categories } from "src/components/games-list/constants/categories-list";
import useGamesList, { IMappedGame } from "src/games/hooks/useGamesList";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { SkeletonCard } from "src/components/skeleton-card/SkeletonCard";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { useCurrentUsedApi } from "src/core/application/hooks/useCurrentUsedApi";
import { Button } from "src/components/shadcn-button/Button";
import GameThumbnail from "src/components/game-thumbnail/GameThumbnail";
import VirtualizedGrid from "src/components/virtualized-grid/VirtualizedGrid";

const cx = classNames.bind(styles);

export default function GamesList() {
    const isMobileOnly = useMobileOnly();
    const params = useParams<{ category: CasinoGameCategory | undefined }>();

    const gameCategoryFromUrl = (params?.category || "") as CasinoGameCategory | ""
    const { games, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useGamesList(gameCategoryFromUrl);
    const { shouldUseHandcash } = useCurrentUsedApi()

    const getThumbnailSkeletons = (withWrapper = true, amount = 30) => {
        const thumbnails = Array.from({ length: amount }).map((_, i) => <SkeletonCard key={i} />)
        return withWrapper ? (
            <div className={cx("games")}>
                {thumbnails}
            </div>
        ) : thumbnails
    };

    const renderItem = ({ gameId, thumbnailSrc, provider, isDisabled, url, title }: IMappedGame) => (
        <GameThumbnail key={gameId} url={url} provider={provider} thumbnailSrc={thumbnailSrc} title={title} isDisabled={isDisabled} />
    );

    return (
        <>
            <div className={cx("category-selection")}>
                {categories.map(({ name, getTitle, icon, path }) => (
                    <div key={name}>
                        <Link
                            to={path}
                            className={cx({ selected: (name === "" && gameCategoryFromUrl === "") || gameCategoryFromUrl === name })}
                            aria-label={getTitle(isMobileOnly)}
                        >
                            {icon}
                        </Link>
                        <span className={cx("small whitespace-nowrap")}>{getTitle(isMobileOnly)}</span>
                    </div>
                ))}
            </div>

            <div>
                {isLoading ? getThumbnailSkeletons() : <VirtualizedGrid
                    items={games} renderItem={renderItem} isFetchingMore={isFetchingNextPage} getSkeletonsCallback={() => getThumbnailSkeletons(false)}
                />}
            </div>

            {!shouldUseHandcash &&
                <div className="flex justify-center items-center mt-8">
                    <Button size="lg" className="text-base" disabled={isFetchingNextPage || !hasNextPage} onClick={() => fetchNextPage()}>
                        Load more games
                    </Button>
                </div>
            }
        </>
    );
}