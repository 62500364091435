import PageContainer from "src/containers/PageContainer/PageContainer";
import { CardWithImage } from "src/components/card-with-image/CardWithImage";
import topHeadingImage from "src/containers/Landing/assets/images/top-header.png";
import bottomHeadingImage from "src/containers/Landing/assets/images/bottom-hero.png";
import { BottomLandingSections } from "src/containers/Landing/components/bottom-landing-sections/BottomLandingSections";
import { StatisticsSection } from "src/containers/Landing/components/statistics-section/StatisticsSection";
import { CarouselWithSingleCategory } from "src/containers/Landing/components/carousel-with-single-category/CarouselWithSingleCategory";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import config from "src/config";
import { Path } from "src/core/paths/paths";

export const NotAuthorizedUserLanding = () => {
    const isWalletConnectEnabled = config.enableWalletConnect;

    return (
        <PageContainer showHeader showFooter dark contentClass="flex flex-col px-4 md:px-8 xl:px-[70px] pt-6 md:pt-10">
            <CardWithImage
                titleTag="h1"
                title="Experience true freedom with casino gaming"
                imageSrc={topHeadingImage}
                description="Connect your wallet and enjoy secure, no-registration-required casino games today!"
                buttonText="Play Now"
                anchorHref="/games"
                gradientDirection="to-right"
            />

            <div className="flex flex-col gap-6 md:gap-8 py-8 md:py-10">
                {/* TODO: Switch In-house row to Trending Games when ready */}
                {/*<TrendingGames />*/}
                {/*TODO: show when backend endpoints will be ready*/}
                {/*<BigWinsSection />*/}
                <CarouselWithSingleCategory headingTitle="In-house" category={CasinoGameCategory.InHouse} seeAllLink={Path.InHouse} />
                <CarouselWithSingleCategory headingTitle="Table Games" category={CasinoGameCategory.Table} seeAllLink={Path.Table} />
                <CarouselWithSingleCategory headingTitle="Slots" category={CasinoGameCategory.Slots} seeAllLink={Path.Slots} />
                {isWalletConnectEnabled &&
                    <CarouselWithSingleCategory headingTitle="Crash" category={CasinoGameCategory.Crash} seeAllLink={Path.Crash} />
                }
                <StatisticsSection />
                <BottomLandingSections />
                <CardWithImage
                    title="Join the world of blockchain casino gaming"
                    imageSrc={bottomHeadingImage}
                    description="Connect your wallet now and enjoy instant access to our top games."
                    buttonText="Play Now"
                    anchorHref="/games"
                    variant="secondary"
                    gradientDirection="to-bottom"
                />
            </div>
        </PageContainer>
    );
}