import { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { axiosInstance } from "src/core/http/axios-instance";
import PageContainer from "../PageContainer/PageContainer";
import styles from "./assets/styles.scss";
import CountdownTimer from "./CountdownTimer";
import RaceInfo from "./RaceInfo";
import RankingsTable from "containers/Races/RankingsTable";
import { useMobileOnly } from "helpers/responsive";

import ImgDailyRace from "./assets/images/daily-race.png";
import ImgWeeklyRace from "./assets/images/weekly-race.png";
import UpdateIcon from "./assets/images/update-icon.svg?inline";

const cx = classNames.bind(styles);

async function getSettings() {
    return (await axiosInstance.get("/v1/leaderboard/setting")).data;
}

async function getRace(season, round) {
    return (
        await axiosInstance.get("/v1/leaderboard/ranking", {
            params: { season, round },
        })
    ).data;
}

export default function Races() {
    const isMobileOnly = useMobileOnly();
    const queryClient = useQueryClient();
    const buttonResetTimeout = /** @type {import("react").MutableRefObject<number>} */ useRef();
    const [type, setType] = useState(null);
    const [round, setRound] = useState("curr");
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const { data: settings } = useQuery({ queryKey: ["settings", "races"], queryFn: getSettings });
    const { data = {}, isLoading, isFetching } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ["race", type, round],
        queryFn: () => getRace(settings[round][type].season, round),
        refetchOnWindowFocus: true,
        enabled: !!settings && !!type,
    });

    const { user_rank: userRank, ranking = [] } = data;

    useEffect(() => {
        // update type based on settings
        if (settings) {
            setType(settings[round].weekly.enable ? "weekly" : "daily");
        }
    }, [settings]);

    useEffect(() => {
        if (isFetching) {
            clearTimeout(buttonResetTimeout.current);
            setButtonDisabled(true);
        } else {
            buttonResetTimeout.current = window.setTimeout(() => setButtonDisabled(false), 10 * 1000)
        }
    }, [isFetching]);

    const raceInfoComponent = <RaceInfo />;
    const updateComponent = (
        <button
            type="button"
            className={cx("update-button")}
            disabled={buttonDisabled}
            onClick={() => queryClient.invalidateQueries(["race", type, round])}
        >
            Update
            <UpdateIcon />
        </button>
    );

    return (
        <PageContainer pageTitle="Races" pageDescription="Peergame Races" showHeader dark contentClass={cx("page")}>
            <div className={cx("content-container")}>
                <div className={cx("top-bar")}>
                    <div className={cx("tab-container")}>
                        {settings?.[round].weekly.enable && (
                            <button type="button" className={cx({ active: type === "weekly" })} onClick={() => setType("weekly")}>
                                <img src={ImgWeeklyRace} alt="Weekly race" />
                                <span className={cx({ xlarge: !isMobileOnly }, "bold")}>Weekly Race</span>
                            </button>
                        )}
                        {settings?.[round].daily.enable && (
                            <button type="button" className={cx({ active: type === "daily" })} onClick={() => setType("daily")}>
                                <img src={ImgDailyRace} alt="Daily race" />
                                <span className={cx({ xlarge: !isMobileOnly }, "bold")}>Daily Race</span>
                            </button>
                        )}
                    </div>
                    {!isMobileOnly && raceInfoComponent}
                </div>
                <div className={cx("main-content")}>
                    <div className={cx("top-section")}>
                        {/* eslint-disable-next-line camelcase */}
                        {settings?.[round][type]?.game_name && (
                            <span className={cx("top-message")}>
                {/* eslint-disable-next-line camelcase */}
                                Play <span>{settings?.[round][type]?.game_name} </span>
                to get {type === "weekly" ? "Weekly" : "Daily"} Race points
              </span>
                        )}
                        <div className={cx("info-squares")}>
                            <div>
                                <p className={cx("small")}>Prize Pool</p>
                                <h2>
                                    {/* eslint-disable-next-line camelcase */}
                                    {settings?.[round][type]?.total_reward} BSV
                                </h2>
                            </div>
                            <div>
                                <p className={cx("small")}>Winners</p>
                                <h2>10</h2>
                            </div>
                        </div>
                        {/* eslint-disable-next-line camelcase */}
                        <CountdownTimer endsAt={settings?.curr[type]?.end_time} />
                        {isMobileOnly && (
                            <div className={cx("mobile-buttons")}>
                                {raceInfoComponent}
                                {updateComponent}
                            </div>
                        )}
                        <div className={cx("my-stats")}>
                            <div>
                                <span>Your Rank</span>
                                <span>{userRank ? userRank.ranking : "-"}</span>
                            </div>
                            <div>
                                <span>Your Points</span>
                                <span>{userRank ? userRank.point : "-"}</span>
                            </div>
                        </div>
                    </div>
                    <div className={cx("table-top-bar")}>
                        <div className={cx("round-selector")}>
                            <button type="button" className={cx({ active: round === "curr" })} onClick={() => setRound("curr")}>
                                Current Race
                            </button>
                            <button type="button" className={cx({ active: round === "prev" })} onClick={() => setRound("prev")}>
                                Last Race
                            </button>
                        </div>
                        {!isMobileOnly && updateComponent}
                    </div>
                    <RankingsTable
                        rewards={settings?.[round][type]?.rewards || []}
                        rankings={ranking}
                        isLoading={isLoading || isFetching}
                    />
                </div>
            </div>
        </PageContainer>
    );
}
