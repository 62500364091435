import { useHandleGames } from "src/containers/Landing/hooks/useHandleGames";
import { ToggleGroup, ToggleGroupItem } from "src/components/toggle-group/ToggleGroup";
import { gameSections } from "src/containers/Landing/constants/gameSections";
import { BasicCarousel } from "src/components/basic-carousel/BasicCarousel";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { useState } from "react";
import { SkeletonCard } from "src/components/skeleton-card/SkeletonCard";
import { useDesktopOnly } from "src/helpers/responsive";
import { CarouselItem } from "src/components/carousel/Carousel";
import { SectionHeading } from "src/containers/Landing/components/section-heading/SectionHeading";

export const TrendingGames = () => {
    const isDesktopOnly = useDesktopOnly();
    const { getGamesThumbnail, isLoading } = useHandleGames();

    const [selectedCategory, setSelectedCategory] = useState(CasinoGameCategory.InHouse)

    const currentGameSection = gameSections.find(({ category }) => selectedCategory === category);

    return (
        <section>
            <SectionHeading
                title="Trending Games"
            />

            <div className="flex items-center my-4 gap-4 border-b border-brand-primary-300 py-2">
                <ToggleGroup type="single">
                    {gameSections.map(({ title, category }) => (
                        <ToggleGroupItem
                            data-state={category === selectedCategory ? "on" : "off"}
                            aria-checked={category === selectedCategory}
                            key={category}
                            value={category}
                            aria-label={`Toggle ${category}`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {title}
                        </ToggleGroupItem>
                    ))}
                </ToggleGroup>
            </div>

            <BasicCarousel seeAllLink={currentGameSection?.path}>
                {isLoading ? Array.from({ length: currentGameSection?.limitSize(isDesktopOnly) || 10 }).map((_, i) => (
                    <CarouselItem key={i} className="min-w-[204px] sm:min-w-[230px]">
                        <SkeletonCard />
                    </CarouselItem>
                )) : getGamesThumbnail(selectedCategory)}
            </BasicCarousel>
        </section>
    )
}