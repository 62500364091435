import { Headset } from "lucide-react";

const iconSize = 16;

export const SupportCallToAction = () => (
    <a
        href="https://help.peergame.com/"
        className="w-full md:w-52 border-input inline-flex gap-2 items-center justify-center whitespace-nowrap text-sm font-bold ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 transition-colors text-brand-white p-2 rounded-[10px] border border-brand-white bg-transparent hover:bg-foreground/40 hover:border-brand-white/80"
    >
        <Headset width={iconSize} height={iconSize} />
        Help Center
    </a>
)