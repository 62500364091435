import discordLogo from "src/containers/Landing/assets/images/discord.svg";
import twitterLogo from "src/containers/Landing/assets/images/twitter-x.svg";
import telegramLogo from "src/containers/Landing/assets/images/telegram.svg";
import bitcoinTalkLogo from "src/containers/Landing/assets/images/bitcoin-talk.svg";
import { cn } from "src/helpers/cn";

interface ISocialMediaItem {
    imgSrc: string;
    title: string;
    url: string;
}

const socialMedia: ISocialMediaItem[] = [
    {
        imgSrc: discordLogo,
        title: "Discord",
        url: "https://discord.gg/cH4jbGS",
    },
    {
        imgSrc: twitterLogo,
        title: "Twitter",
        url: "https://twitter.com/PeergameEng",
    },
    {
        imgSrc: telegramLogo,
        title: "Telegram",
        url: "https://t.me/peergame",
    },
    {
        imgSrc: bitcoinTalkLogo,
        title: "Bitcoin Talk",
        url: "https://bitcointalk.org/index.php?topic=5257254",
    },
]

interface ISocialMediaLinksProps {
    className?: string;
}

export const SocialMediaLinks = ({ className }: ISocialMediaLinksProps) => (
    <div className={cn("flex flex-col gap-2 justify-center items-center", className)}>
        <h6>Join us</h6>
        <ul className="flex gap-6 items-center">
            {socialMedia.map(({ imgSrc, url, title }) => (
                <li key={title}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        <img src={imgSrc} alt={title} />
                    </a>
                </li>
            ))}
        </ul>
    </div>
)