import { TableRow, TableCell } from "src/components/shadcn-table/Table";
import { TableRowSkeleton } from "src/components/stats-table/components/table-row-skeleton/TableRowSkeleton";
import { hideStyles } from "src/components/stats-table/assets/styles";

export const StatsTableSkeletonRow = () => (
    <TableRow className="border-none text-xs">
        <TableCell className="pl-10">
            <TableRowSkeleton />
        </TableCell>
        <TableCell>
            <TableRowSkeleton />
        </TableCell>
        <TableCell className={hideStyles}>
            <TableRowSkeleton />
        </TableCell>
        <TableCell className={hideStyles}>
            <TableRowSkeleton />
        </TableCell>
        <TableCell className={hideStyles}>
            <TableRowSkeleton />
        </TableCell>
        <TableCell className={hideStyles}>
            <TableRowSkeleton />
        </TableCell>
        <TableCell>
            <TableRowSkeleton />
        </TableCell>
    </TableRow>
);