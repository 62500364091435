import { SectionHeading } from "src/containers/Landing/components/section-heading/SectionHeading";
import { BasicCarousel } from "src/components/basic-carousel/BasicCarousel";
import { CarouselItem } from "src/components/carousel/Carousel";
import { SkeletonCard } from "src/components/skeleton-card/SkeletonCard";
import { useHandleGames } from "src/containers/Landing/hooks/useHandleGames";
import { CasinoGameCategory } from "src/core/casino/casino.model";

export const ContinuePlayingSection = () => {
    // TODO: Use correct endpoint when it'll be ready
    const { getGamesThumbnail, isLoading } = useHandleGames();

    return (
        <section>
            <SectionHeading
                title="Continue Playing"
                className="mb-8"
            />

            <BasicCarousel displayArrows={false}>
                {isLoading ? Array.from({ length: 10 }).map((_, i) => (
                    <CarouselItem key={i} className="min-w-[230px] sm:min-w-[284px]">
                        <SkeletonCard />
                    </CarouselItem>
                )) : getGamesThumbnail(CasinoGameCategory.Slots, "big")}
            </BasicCarousel>
        </section>
    )
}