import { ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";

const iconSize = 16;

export const AboutCallToAction = () => (
    <Link to="/about-us" className="flex gap-2 items-center text-sm font-bold text-brand-primary-300">
        Read More
        <ChevronRight width={iconSize} height={iconSize} />
    </Link>
)