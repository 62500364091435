import { ToggleGroup, ToggleGroupItem } from "src/components/toggle-group/ToggleGroup";
import { useState } from "react";
import { StatsTable } from "src/components/stats-table/StatsTable";
import { StatsCategory } from "src/containers/Landing/components/statistics-section/types/statisticSections";

export const statisticSections = [
    {
        title: "All Bets",
        category: StatsCategory.AllBets,
    },
    {
        title: "High Rollers",
        category: StatsCategory.HighRollers,
    },

];

export const StatisticsSection = () => {
    const [selectedCategory, setSelectedCategory] = useState(StatsCategory.AllBets)

    return (
        <section>
            <h2 className="text-[24px] leading-5 text-white py-6">All Bets</h2>

            <div className="bg-brand-primary-500 rounded-lg">
                <div className="flex items-center gap-4 border-b border-brand-primary-300 pt-6 pb-2 mx-10">
                    <ToggleGroup type="single">
                        {statisticSections.map(({ title, category }) => (
                            <ToggleGroupItem
                                data-state={category === selectedCategory ? "on" : "off"}
                                aria-checked={category === selectedCategory}
                                key={category}
                                value={category}
                                aria-label={`Toggle ${category}`}
                                onClick={() => setSelectedCategory(category)}
                            >
                                {title}
                            </ToggleGroupItem>
                        ))}
                    </ToggleGroup>
                </div>

                <StatsTable category={selectedCategory} />
            </div>
        </section>
    )
}