import { CarouselItem } from "src/components/carousel/Carousel";
import GameThumbnail from "src/components/game-thumbnail/GameThumbnail";
import { cn } from "src/helpers/cn";

export type ThumbnailVariant = "big" | "small" | "withText";

interface GameCardCarouselItemProps {
    title: string;
    url: string;
    thumbnailSrc: string;
    provider: string;
    isDisabled: boolean;
    className?: string;
    variant?: ThumbnailVariant;
    userName?: string;
    winAmount?: string;
    userAvatar?: string;
}

export const GameCardCarouselItem = ({
    url,
    provider,
    isDisabled,
    title,
    thumbnailSrc,
    variant = "small",
    className,
    userName,
    winAmount,
    userAvatar,
}: GameCardCarouselItemProps) => {
    const variants = {
        big: "min-w-[230px] sm:min-w-[284px]",
        small: "min-w-[234px] sm:min-w-[230px]",
        withText: "min-w-[271px]",
    }

    return (
        <CarouselItem className={cn(variants[variant], className)}>
            <GameThumbnail
                title={title}
                url={url}
                thumbnailSrc={thumbnailSrc}
                provider={provider}
                isDisabled={isDisabled}
                userName={userName}
                winAmount={winAmount}
                userAvatar={userAvatar}
                className="aspect-square w-full"
            />
        </CarouselItem>
    )
}