import { ReactElement } from "react";
import IconSlots from "../../../assets/img/slots.svg?inline";
import IconTableGames from "../../../assets/img/table-games.svg?inline";
import IconGames from "../../../assets/img/games.svg?inline";
import IconInhouse from "../assets/inhouse.svg?inline";
import IconCasual from "../assets/casual.svg?inline";
import { CasinoGameCategory } from "src/core/casino/casino.model";
import { Path } from "src/core/paths/paths";

interface ICategories {
    name: string;
    getTitle: (isMobileOnly?: boolean) => string;
    icon: ReactElement;
    path: Path
}

export const categories: ICategories[] = [
    { name: "", getTitle: (isMobileOnly: boolean) => (isMobileOnly ? "All" : "All Games"), icon: <IconGames />, path: Path.Games },
    { name: CasinoGameCategory.InHouse, getTitle: () => "In-House", icon: <IconInhouse />, path: Path.InHouse },
    { name: CasinoGameCategory.Slots, getTitle: () => "Slots", icon: <IconSlots />, path: Path.Slots },
    { name: CasinoGameCategory.Table, getTitle: () => "Table", icon: <IconTableGames />, path: Path.Table },
    { name: CasinoGameCategory.Casual, getTitle: () => "Casual", icon: <IconCasual />, path: Path.Casual },
];