import { cn } from "src/helpers/cn";

interface ISectionHeadingProps {
    title: string;
    description?: string;
    className?: string;
}

export const SectionHeading = ({ description, title, className }: ISectionHeadingProps) => (
    <div className={cn("flex flex-col gap-4", className)}>
        <h2 className="text-[24px] leading-5 text-white">{title}</h2>
        {!!description && <h3 className="text-brand-primary-300 text-lg max-w-[50ch] font-normal">{description}</h3>}
    </div>
)