import { CardWithImage } from "src/components/card-with-image/CardWithImage";
import referralHeroImage from "src/containers/Landing/assets/images/referral-hero.png";
import { SimpleCard } from "src/simple-card/SimpleCard";
import { AboutCallToAction } from "src/containers/Landing/components/about-call-to-action/AboutCallToAction";
import { SupportCallToAction } from "src/containers/Landing/components/support-call-to-action/SupportCallToAction";

export const BottomLandingSections = () => (
    <>
        <CardWithImage
            title="Refer a Friend"
            imageSrc={referralHeroImage}
            description="Invite your friends to PeerGame and earn commissions every time they bet on our blockchain casino games. The more they play, the more you earn"
            buttonText="Get Refferal Link"
            anchorHref="/partners"
            variant="secondary"
            imageClassname="mb-[-40px] md:mb-0"
            gradientDirection="to-right"
            imagePlacement="bottom"
            buttonClassname="text-brand-white p-2 rounded-[10px] border border-brand-white bg-transparent hover:bg-foreground/40 hover:border-brand-white/80"
        />

        <section className="flex flex-col lg:flex-row gap-6">
            <SimpleCard
                title="About"
                description="PeerGame is a leading blockchain casino that prioritizes transparency, security, and privacy, offering a seamless gaming experience with direct wallet connections and instant payouts. Whether you're new to blockchain gaming or a seasoned player, PeerGame provides a world-class selection of casino games designed for all."
                callToAction={<AboutCallToAction />}
            />
            <SimpleCard
                title="Support"
                description="Need assistance? Explore our Help Center for FAQs, detailed guides and contact options to get help with PeerGame’s casino games."
                callToAction={<SupportCallToAction />}
            />
        </section>
    </>
)