import AboutUs from "src/static/AboutUs";
import FairnessCalculation from "src/containers/FairnessCalculation/FairnessCalculation";
import ProvablyFair from "src/static/ProvablyFair";
import BitcoinSV from "src/static/BitcoinSV";
import ResponsibleGaming from "src/static/ResponsibleGaming";
import PrivacyPolicy from "src/static/PrivacyPolicy";
import TermsOfService from "src/static/TermsOfService";
import Payments from "src/static/Payments";
import Cookies from "src/static/Cookies";
import Complaints from "src/static/Complaints";
import KycPolicy from "src/static/KycPolicy";
import { Path } from "src/core/paths/paths";

export default [
    {
        path: Path.AboutUs,
        component: <AboutUs />,
        title: "About Us",
        category: "product",
    },
    {
        path: Path.FairnessCalculation,
        component: <FairnessCalculation />,
        title: "Fairness Calculation",
        category: "product",
        hideTitle: true,
    },
    {
        path: Path.ProvablyFair,
        component: <ProvablyFair />,
        title: "Provably Fair",
        category: "product",
    },
    {
        path: Path.Bsv,
        component: <BitcoinSV />,
        title: "Bitcoin SV",
        category: "product",
    },
    {
        path: Path.ResponsibleGaming,
        component: <ResponsibleGaming />,
        title: "Responsible Gaming",
        category: "product",
    },
    {
        path: Path.PrivacyPolicy,
        component: <PrivacyPolicy />,
        title: "Privacy Policy",
        category: "service",
    },
    {
        path: Path.TermsOfService,
        component: <TermsOfService />,
        title: "Terms of Service",
        category: "service",
    },
    {
        path: Path.Payments,
        component: <Payments />,
        title: "Payments",
        category: "product",
    },
    {
        path: Path.Cookies,
        component: <Cookies />,
        title: "Cookies",
        category: "service",
    },
    {
        path: Path.Complaints,
        component: <Complaints />,
        title: "Complaints",
        category: "service",
    },
    {
        path: Path.KycPolicy,
        component: <KycPolicy />,
        title: "AML/KYC Policy",
        category: "service",
    },
];
